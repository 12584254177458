<template>
    <v-row>

      <v-col cols="12" lg="12" sm="12" align="center">
        <v-alert color="primary" dark icon="mdi-account-credit-card-outline" border="left" prominent>
          <div class="font-weight-bold">
            Paga tus compras con saldo de tu cuenta de Mercado Pago!
          </div>
        </v-alert>
      </v-col>

      <v-col align="center" class="py-9">
        <v-progress-circular
          :size="200"
          :width="8"
          color="primary"
          indeterminate
        >
          <b>Espere un momento!</b>
        </v-progress-circular>
      </v-col>
      
    </v-row>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  props: {
    order: {
      type: Object,
      default: {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {};
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    getPayment() {
      let ocId = this.order.ocId;

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/mercadopago/orders/${ocId}`;
      } else {
        url += `/public/mercadopago/orders/${ocId}`;
      }

      axios
        .post(url, {
          success: `/#/orders/${ocId}`,
        })
        .then((res) => {
          // SE OBTIENE EL CUERPO DE LA RESPUESTA DE MANERA SEGURA
          const body = _.get(res, "data.data", {});

          // SE REDIRIGE AL USUARIO AL FORMULARIO DE PAGO DE MERCADOPAGO
          window.location.href = body.sandboxInitPoint;
        })
        .catch(() => {
          // DOES NOTHING
        });
    },
  },
};
</script>